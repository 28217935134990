import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  viewBox: "0 0 10 10"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "m6.1 5.1-.6.7V3c0-.3-.2-.5-.5-.5s-.5.2-.5.5v2.8l-.6-.7c-.2-.1-.6-.1-.8 0s-.1.6 0 .8l1.5 1.5s.1.1.2.1h.4c.1 0 .1-.1.2-.1l1.5-1.5c.1-.2.1-.6 0-.8s-.6-.1-.8 0" }, null, -1),
    _createElementVNode("path", { d: "M8.5 0h-7C.7 0 0 .7 0 1.5v7C0 9.3.7 10 1.5 10h7c.8 0 1.5-.7 1.5-1.5v-7C10 .7 9.3 0 8.5 0M9 8.5c0 .3-.2.5-.5.5h-7c-.3 0-.5-.2-.5-.5v-7c0-.3.2-.5.5-.5h7c.3 0 .5.2.5.5z" }, null, -1)
  ])))
}
export default { render: render }