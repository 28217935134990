import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  viewBox: "0 0 8 9"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M7.2 6.1c-.2 0-.4-.1-.5-.2L4 3.2 1.3 5.9c-.3.3-.7.3-1 0s-.3-.7 0-1l3-3c.4-.4 1-.4 1.3 0l3 3c.3.3.3.7 0 1 0 .1-.2.2-.4.2M3.7 2.9" }, null, -1)
  ])))
}
export default { render: render }