import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  viewBox: "0 0 8 9"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M4 7.2c-.2 0-.5-.1-.7-.3l-3-3c-.3-.3-.3-.7 0-1s.7-.3 1 0L4 5.6l2.7-2.7c.3-.3.7-.3 1 0s.3.7 0 1l-3 3c-.2.2-.5.3-.7.3" }, null, -1)
  ])))
}
export default { render: render }