import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 10 10"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#000",
      "fill-rule": "evenodd",
      d: "M3.5 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4M0 8.5c-.001-1.91 1.589-3.502 3.5-3.502C5.41 4.998 7 6.588 7 8.5h-.5.5v1a.5.5 0 0 1-.5.5h-6a.5.5 0 0 1-.5-.5zh.5zM9.5 10H7.915q.084-.236.085-.5v-1c0-1.404-.66-2.668-1.684-3.497q.09-.005.183-.005c1.911 0 3.502 1.59 3.501 3.502v1a.5.5 0 0 1-.5.5m-3-6q-.352-.002-.667-.114A3 3 0 0 0 6.5 2c0-.715-.25-1.37-.667-1.886A2 2 0 1 1 6.5 4",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }