import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 10 10"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#000",
      "fill-rule": "evenodd",
      d: "M7.885 2.12a1.37 1.37 0 0 0-1.938-.002l-.916.915A.75.75 0 0 1 3.97 1.97l.917-.915A2.871 2.871 0 1 1 8.943 5.12l-.913.912A.75.75 0 1 1 6.97 4.97l.913-.912a1.37 1.37 0 0 0 .001-1.94ZM3.031 3.97a.75.75 0 0 1 0 1.06l-.917.92a1.37 1.37 0 0 0 1.94 1.937l.916-.916a.75.75 0 0 1 1.061 1.06l-.917.917a2.87 2.87 0 0 1-4.062-4.057l.918-.92a.75.75 0 0 1 1.06-.001Zm3.647.415a.75.75 0 0 0-1.061-1.061L3.324 5.618a.75.75 0 1 0 1.06 1.06l2.294-2.292Z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }