import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "data-name": "Layer 2",
  viewBox: "0 0 28 28"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M14 0C6.27 0 0 6.27 0 14s6.27 14 14 14 14-6.27 14-14S21.73 0 14 0m-3.43 19.8H7.74v-9.12h2.83zM9.14 9.56c-.9 0-1.47-.63-1.47-1.42s.6-1.42 1.51-1.42 1.47.62 1.49 1.42c0 .78-.58 1.42-1.53 1.42M20.93 19.8H18.1v-5.06c0-1.18-.41-1.98-1.44-1.98-.78 0-1.25.54-1.45 1.06-.08.19-.09.45-.09.71v5.26h-2.84v-6.21c0-1.14-.04-2.09-.07-2.91h2.46l.13 1.27h.06c.37-.6 1.29-1.47 2.82-1.47 1.87 0 3.26 1.25 3.26 3.94v5.39Z",
      "data-name": "Layer 1"
    }, null, -1)
  ])))
}
export default { render: render }