import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "data-name": "Layer 2",
  viewBox: "0 0 28 28"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M14 0C6.27 0 0 6.27 0 14s6.27 14 14 14 14-6.27 14-14S21.73 0 14 0m6.73 10.55V11c0 4.59-3.49 9.87-9.88 9.87-1.97 0-3.79-.57-5.33-1.56.28.03.55.04.84.04 1.62 0 3.12-.55 4.31-1.48a3.45 3.45 0 0 1-3.24-2.41c.53.08 1.02.08 1.57-.06-.79-.16-1.49-.59-2-1.21a3.46 3.46 0 0 1-.78-2.2v-.04c.46.26 1 .42 1.57.44-.48-.32-.87-.75-1.14-1.25s-.41-1.07-.41-1.64c0-.65.17-1.24.47-1.75a10 10 0 0 0 3.19 2.58c1.23.63 2.58.98 3.96 1.05-.49-2.36 1.27-4.27 3.39-4.27 1 0 1.9.42 2.53 1.09.78-.15 1.53-.44 2.2-.83-.26.8-.8 1.48-1.52 1.91.7-.07 1.38-.27 2-.54-.47.69-1.06 1.31-1.74 1.8Z",
      "data-name": "Layer 1"
    }, null, -1)
  ])))
}
export default { render: render }