import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "data-name": "Layer 1",
  viewBox: "0 0 4.5 7.81"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M.7 7.81a.705.705 0 0 1-.5-1.2L2.91 3.9.21 1.2C-.07.92-.07.48.21.21s.71-.28.99 0l3.03 3.03c.18.18.28.42.28.67s-.1.49-.28.67L1.2 7.61c-.14.14-.32.21-.5.21Zm2.53-3.58h.01z",
      style: {"stroke-width":"0"}
    }, null, -1)
  ])))
}
export default { render: render }